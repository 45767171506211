import React from 'react';
import styles from './style.module.scss';
import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';

interface TopPicksProps {
  // data: React.ReactNode[];
}

const TopPicks: React.FC<TopPicksProps> = () => {
  const categoryData = [
    {
      href: 'sofa-sets',
      imgSrc: '/images/home-new1/icon1.jpg',
      altText: 'Wooden Sofa Set Furniture',
      categoryName: 'Sofa Sets',
    },
    {
      href: 'beds',
      imgSrc: '/images/home-new1/icon2.jpg',
      altText: 'Wooden Bed Furniture',
      categoryName: 'Beds',
    },
    {
      href: 'dining-table-sets',
      imgSrc: '/images/home-new1/icon3.jpg',
      altText: 'Wooden Dining Furniture',
      categoryName: 'Dining Table Sets',
    },
    {
      href: 'sofa-cum-beds',
      imgSrc: '/images/home-new1/icon4.jpg',
      altText: 'Wooden Sofa Come Bed Furniture',
      categoryName: 'Sofa Cum Beds',
    },
    {
      href: 'tv-units',
      imgSrc: '/images/home-new1/icon10.jpg',
      altText: 'Wooden Tv Unit Furniture',
      categoryName: 'TV Units',
    },
    {
      href: 'bookshelves',
      imgSrc: '/images/home-new1/icon7.jpg',
      altText: 'Wooden Bookshelf Furniture Online',
      categoryName: 'Book Shelves',
    },
    {
      href: 'coffee-tables',
      imgSrc: '/images/home-new1/icon8.jpg',
      altText: 'Buy Coffee Table for Home | Wooden Coffee Table Furniture',
      categoryName: 'Coffee Tables',
    },
    {
      href: 'study-tables',
      imgSrc: '/images/home-new1/icon6.jpg',
      altText: 'Wooden Study Table Furniture',
      categoryName: 'Study Tables',
    },
    {
      href: 'shoe-racks',
      imgSrc: '/images/home-new1/icon13.jpg',
      altText: 'Wooden Shoe Racks',
      categoryName: 'Shoe Racks',
    },
    {
      href: 'home-decors',
      imgSrc: '/images/home-new1/icon11.jpg',
      altText: 'Home Decor Items',
      categoryName: 'Home Decor',
    },
    {
      href: 'home-furnishing',
      imgSrc: '/images/home-new1/icon9.jpg',
      altText: 'Home Furnishing Items',
      categoryName: 'Home Furnishing',
    },
    {
      href: 'lamps-and-lighting',
      imgSrc: '/images/home-new1/icon12.jpg',
      altText: 'Lamp Lights Online in India',
      categoryName: 'Lamps & Lightings',
    },
  ];

  // const customLoader = ({ src }: { src: string }) => {
  //     return `${src}`;
  // };

  return (
    <section className={styles.toppickssection}>
      <div className={styles.toppicksinner}>
        <div className="container-1240">
          <div className="wdRow ">
            <div className="wdCol-lg-12">
              <div className={styles.toppicksheading}>
                <h2>
                  Let us Help you Explore your Favourite Furniture Category!
                </h2>
                {/* <p>Impressive Collection For Your Dream Home</p> */}
              </div>
            </div>
          </div>

          <div className={styles.toppickscontent}>
            <div className="wdRow">
              {categoryData.map((category, index) => (
                <div
                  className="wdCol-lg-2 wdCol-md-3 wdCol-sm-4 wdCol-6"
                  key={index}
                >
                  <CustomLink
                    href={category.href}
                    className={styles.toppickshecard}
                  >
                    <div className={styles.toppicksimg}>
                      <WsStaticImage
                        src={category.imgSrc}
                        alt={category.altText}
                        title={category.categoryName}
                        width={192}
                        height={163}
                        // loading="lazy"
                        quality={75}
                        style={{ width: '192px', height: '163px' }}
                      />
                    </div>
                    <div className={styles.toppickstext}>
                      <p className={styles.categoryname}>
                        {category.categoryName}
                      </p>
                    </div>
                  </CustomLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopPicks;
