import React from 'react';
import styles from './style.module.scss';
import { useMediaQuery } from '@react-hook/media-query';
// import dynamic from 'next/dynamic';
import WsStaticImage from '../common/WsStaticImage';
import TopPicksMobile from '../home/mobile/top-picks-mobile';
import TopPicks from './top-picks';
import CustomLink from '../CustomLink';

// const TopPicksMobile = dynamic(() => import('./mobile/top-picks'));
// const TopPicks = dynamic(() => import('./top-picks'));

const Error404Component = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className={styles['cart-body']}>
      <div className={`${styles['cart-empty-section']}`}>
        <section className={`${styles['error']} ${styles['cart-empty']}`}>
          <div className="container-1240">
            <div className={styles['cart-empty-content']}>
              <div className={styles['cart-empty-img']}>
                <WsStaticImage
                  src={'/images/common/not-found.svg'}
                  height={316}
                  width={500}
                  loading="eager"
                  priority
                  quality={75}
                  alt="empty cart"
                  title="empty cart"
                />
              </div>
              <CustomLink href="/" className={styles['commen_btn']}>
                Back To Home
              </CustomLink>
            </div>
          </div>
        </section>
        {isMobile ? <TopPicksMobile /> : <TopPicks />}
      </div>
    </div>
  );
};

export default Error404Component;
