export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,}$/;

export const phoneRegex = /^(0|91)?[6-9][0-9]{9}$/;
export const postRegex = /^[0-9]{6}$/;

export const fullNameRegex = /^[a-zA-Z]+ [a-zA-Z. ]+$/;

export const gstNumberRegex =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

export const email =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const inchRegex =
  /(\d+(\.\d+)?)\s*L\s*x\s*(\d+(\.\d+)?)\s*W(?:\s*x\s*(\d+(\.\d+)?))?/i;

export const digitRegex = /^\d+(\.\d+)?$/;

export const digitRegexWithUnit = /^\d+(\.\d+)?\s?[A-Za-z]?$/;
