import WsStaticImage from '@/components/common/WsStaticImage';
import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';

interface TopPicksMobileProps {
  data?: any;
}

const defaultData = {
  component: 'top-picks',
  items: [
    [
      {
        href: '/beds',
        imageSrc: '/images/home-new1/icon2.jpg',
        name: 'Beds',
      },
      {
        href: '/sofa-sets',
        imageSrc: '/images/home-new1/icon16.jpg',
        name: 'Sofa Sets',
      },
      {
        href: '/dressing-tables',
        imageSrc: '/images/home-new1/icon14.jpg',
        name: 'Dressing Tables',
      },
      {
        href: '/sofa-cum-beds',
        imageSrc: '/images/home-new1/icon4.jpg',
        name: 'Sofa Cum Beds',
      },
      {
        href: '/dining-table-sets',
        imageSrc: '/images/home-new1/icon3.jpg',
        name: 'Dining Table Sets',
      },
      {
        href: '/chest-of-drawers',
        imageSrc: '/images/bedroom/icon9.jpg',
        name: 'Chest of Drawers',
      },
      {
        href: '/home-temple',
        imageSrc: '/images/home-decor/icon2.jpg',
        name: 'Home Temple',
      },
      {
        href: '/wardrobes',
        imageSrc: '/images/bedroom/icon3.jpg',
        name: 'Wardrobes',
      },
      {
        href: '/side-tables',
        imageSrc: '/images/living-room-furniture/icon7.jpg',
        name: 'Side & End Tables',
      },
      {
        href: '/home-decors',
        imageSrc: '/images/home-new1/icon11.jpg',
        name: 'Home Decor',
      },
      {
        href: '/home-furnishing',
        imageSrc: '/images/home-new1/icon9.jpg',
        name: 'Home Furnishing',
      },
      {
        href: '/lamps-and-lighting',
        imageSrc: '/images/home-new1/icon12.jpg',
        name: 'Lamps & Lightings',
      },
    ],
    [
      {
        href: '/modular-kitchen-designs',
        imageSrc: '/images/home-new1/icon15.jpg',
        name: 'Modular Kitchen',
      },
      {
        href: '/lounge-chairs',
        imageSrc: '/images/furniture-new/icon9.jpg',
        name: 'Lounge Chairs',
      },
      {
        href: '/shoe-racks',
        imageSrc: '/images/home-new1/icon13.jpg',
        name: 'Shoe Racks',
      },
      {
        href: '/mattress-online',
        imageSrc: '/images/bedroom/icon7.jpg',
        name: 'Mattress',
      },
      {
        href: '/coffee-tables',
        imageSrc: '/images/home-new1/icon8.jpg',
        name: 'Coffee Tables',
      },
      {
        href: '/tv-units',
        imageSrc: '/images/home-new1/icon10.jpg',
        name: 'TV Units',
      },
      {
        href: '/study-tables',
        imageSrc: '/images/home-new1/icon6.jpg',
        name: 'Study Tables',
      },
      {
        href: '/bookshelves',
        imageSrc: '/images/home-new1/icon7.jpg',
        name: 'Book Shelves',
      },
      {
        href: '/three-seater-sofas',
        imageSrc: '/images/home-new1/icon1.jpg',
        name: '3 Seater Sofas',
      },
      {
        href: '/l-shaped-sofas',
        imageSrc: '/images/sofa-sets/icon3.jpg',
        name: 'L Shaped Sofa',
      },
      {
        href: '/outdoor-furniture',
        imageSrc: '/images/outdoor-furniture/icon1.jpg',
        name: 'Outdoor Furniture',
      },
      {
        href: '/office-furniture',
        imageSrc: '/images/study-room/icon4.jpg',
        name: 'Office Furniture',
      },
    ],
  ],
};

const TopPicksMobile: React.FC<TopPicksMobileProps> = ({
  data = defaultData,
}) => {
  return (
    <section className={styles['top-picks-mobile-section']}>
      <div className={styles['top-picks-inner']}>
        <div className={styles['top-picks-content']}>
          {data?.items?.map((category: any, i: number) => {
            return (
              <div className={styles['top-picks-box']} key={i}>
                {category?.map((category: any, i: number) => {
                  return (
                    <Link
                      href={category.href}
                      className={styles['top-pickshe-card']}
                      key={i}
                    >
                      <figure>
                        {i <= 4 ? (
                          <WsStaticImage
                            src={category.imageSrc}
                            classNameCls={''}
                            alt={category.alt ?? 'poster'}
                            width={115}
                            height={99}
                            priority
                            sizes="100vw"
                          />
                        ) : (
                          <WsStaticImage
                            src={category.imageSrc}
                            alt={category.alt ?? 'poster'}
                            classNameCls={'lazy-loading'}
                            width={115}
                            height={99}
                            priority
                            sizes="100vw"
                          />
                        )}
                      </figure>
                      <p className={styles['category-name']}>{category.name}</p>
                    </Link>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TopPicksMobile;
