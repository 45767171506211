import Link, { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';

// Extend LinkProps to include children
interface CustomLinkProps extends LinkProps {
  children: ReactNode;
  className?: string;
  rel?: string;
  target?: string;
  tabIndex?: any;
  prefetch?: boolean;
  style?: any;
}

// CustomLink component with prefetch disabled globally
const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  className,
  rel,
  target,
  tabIndex,
  prefetch = false,
  style,
  ...props
}) => {
  return (
    <Link
      {...props}
      className={className}
      prefetch={prefetch}
      target={target}
      rel={rel}
      tabIndex={tabIndex}
      style={style}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
